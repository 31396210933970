<template>
  <integration-form
    ref="formRef"
    :set="setEmailage"
    :get="getEmailage"
    :fields="fields"
    title="Emailage"
  />
</template>

<script>
import { mapActions } from 'vuex'
import config from './config'
import mixin from '../mixin'

export default {
  name: 'Emailage',
  mixins: [mixin],
  methods: {
    ...mapActions('settingsIntegrations', ['getEmailage', 'setEmailage']),
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>

<style scoped>

</style>
