import { TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const fields = {
    emailage_account_sid: {
      type: TEXT_INPUT,
      label: 'Emailage Account SID',
      rules: 'required',
      placeholder: 'Type...',
    },
    emailage_auth_token: {
      type: TEXT_INPUT,
      label: 'Emailage Auth Token',
      rules: 'required',
      placeholder: 'Type...',
    },
  }
  return {
    fields,
  }
}
